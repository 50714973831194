import {BehaviorSubject} from "rxjs";
import {DmxDocumentService} from "../service/http/dmx-document.service";

export abstract class BasicStore {

  //TODO: Loader und error Handling implementieren, dann müssen diese Subjects in den Komponenten verwendet werden
  private error$ = new BehaviorSubject<string | null>(null);
  private loading$ = new BehaviorSubject<boolean>(false);

  protected constructor(private documentService: DmxDocumentService) {
  }

  public startLoad = () => this.loading$.next(true);
  public stopLoad = () => this.loading$.next(false);
  public isLoading = () => this.loading$.asObservable();
  public getError = () => this.error$.asObservable();

  public handleError(error: string) {
    this.error$.next(error);
    setTimeout(()=>{                           // <<<---using ()=> syntax
      this.stopLoad();
    }, 3000);

  }
}
