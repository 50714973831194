import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {map, Observable, of, retry, shareReplay, take} from "rxjs";
import {
  ComboBoxItem,
  DocumentHeader,
  DocumentModel,
  RundfunkAnstalt,
  Status,
  Workflow
} from "../../model/document.model";
import {DocumentStore} from "../../store/document.store";

@Injectable({
  providedIn: 'root'
})
export class DmxDocumentService {

  private readonly httpHeader = new HttpHeaders();
  private readonly apiUrl: string = environment.terminalApiUrl;

  constructor(private httpClient: HttpClient) {
    this.httpHeader = this.httpHeader.set('Content-Type', 'application/json');
  }

  /**
   * Lädt eine Liste der validen Rundfunkanstalten zur Auswahl
   */
  public loadAllRfas(): Observable<RundfunkAnstalt[]> {
    return this.httpClient.get<RundfunkAnstalt[]>(`${this.apiUrl}`, {headers: this.httpHeader})
      .pipe(map(data => data as RundfunkAnstalt[]), retry({count: 10, delay: 3000}));
  }

  /**
   * Lädt eine Liste der Wettertext-Metadaten für die angegebene RFA sortiert nach Erstellungszeitpunkt absteigend
   * @return
   * @param rfaKuerzel
   */
  public loadDocumentHeaders(rfaKuerzel: string): Observable<DocumentHeader[]> {
    return this.httpClient.get(`${this.apiUrl}/${rfaKuerzel}`, {headers: this.httpHeader})
      .pipe(map(data => data as DocumentHeader[]), retry({count: 10, delay: 3000}));
  }


  /**
   * Lädt ein Wettertext-Document für die angegebene RFA anhand einer ID
   * @param rfaKuerzel
   * @param id
   * @param copyProfile (optional) Name des Kopierprofils
   * @return
   */
  public loadDocumentById(rfaKuerzel: string, id: string, copyProfile?: string): Observable<DocumentModel> {
    const queryParams = copyProfile ? new HttpParams().set('copyProfile', copyProfile) : undefined;

    return this.httpClient.get(`${this.apiUrl}/${rfaKuerzel}/${id}`, {headers: this.httpHeader, params: queryParams})
      .pipe(take(1), shareReplay({bufferSize: 1, refCount: true}), map(data => data as DocumentModel));
  }
  /**
   * Patched (updated) ein Wettertext-Document-Header
   * @param rfaKuerzel, documentHerade
   * @return Observable<HttpResponse<any>>
   */
  public patchDocument(rfaKuerzel: string, documentHeader: DocumentHeader):Observable<HttpResponse<any>> {
    this.httpHeader.set('Content-Type', 'application/json');

    return this.httpClient.patch(`${this.apiUrl}/${rfaKuerzel}/${documentHeader.documentId}`, documentHeader, {
      observe: 'response',
      headers: this.httpHeader,
      responseType: 'json'
    });

  }

  /**
   * Löscht ein Wettertext-Document anhand einer ID
   * @param id
   * @return void
   */
  public deleteDocumentById(id: string | undefined):Observable<HttpResponse<any>> {
    this.httpHeader.set('Content-Type', 'application/json');
    return this.httpClient.delete(`${this.apiUrl}/${id}`, {
      headers: this.httpHeader,
      observe: 'response',
      responseType: 'text'
    });

    //.pipe(take(1)).subscribe(() => {
    //});
    //window.location.reload();
    //return
  }

  public loadDocumentHavarieText(rfaKuerzel: string | undefined, id: string) {
    this.httpHeader.set('Content-Type', 'text/plain')
    return this.httpClient.get(`${this.apiUrl}/${rfaKuerzel}/${id}/havarietext`, {
      headers: this.httpHeader,
      observe: 'response',
      responseType: 'text'
    }).pipe(take(1));
  }

  /**
   * Legt eine neue Wettervorhersage Document an
   * @param rfaKuerzel
   * @param document
   * @return
   */
  public publish(rfaKuerzel: string, document: DocumentModel) {
    return this.httpClient.post(`${this.apiUrl}/${rfaKuerzel}`, document, {
      observe: 'response',
      headers: this.httpHeader,
      responseType: 'json'
    });
  }

  public publishById(rfaKuerzel: string, documentId: string) {
    return this.httpClient.post(`${this.apiUrl}/${rfaKuerzel}/${documentId}`, {}, {
      observe: 'response',
      headers: this.httpHeader,
      responseType: 'json'
    });
  }

  /**
   * Lädt die Standard Wettersymbole für die ComboBoxen
   * @param filterCodes
   */
  public loadStatusList(filterCodes?: string[]): Observable<Status[]> {
    const httpParams = filterCodes ? new HttpParams().set('filter', filterCodes.join(',')) : new HttpParams();
    return this.httpClient.get(`${this.apiUrl}/metadata/status`, {
      params: httpParams
    }).pipe(map((data) => data as Status[]));
  }

  public loadWorkflowList(filterCodes: string[]) {
    return this.httpClient.get(`${this.apiUrl}/metadata/workflow`,
      {
        params: new HttpParams().set('filter', filterCodes.join(','))
      }).pipe(map((data) => data as Workflow[]));
  }

  public loadCopyProfiles(rfaKuerzel: string) {
    return this.httpClient.get(`${this.apiUrl}/${rfaKuerzel}/copyprofiles`).pipe(map(data => data as string[]))
  }

  public loadComboBoxItems(service: string) {
    return this.httpClient.get(`${this.apiUrl}/${service}`, {headers: this.httpHeader}).pipe(map((data) => data as ComboBoxItem[]));
  }

}
