import {Injectable} from "@angular/core";
import {BehaviorSubject, first, take} from "rxjs";
import {DocumentHeader, DocumentModel, UnpublishedDocuments} from "../model/document.model";
import {DmxDocumentService} from "../service/http/dmx-document.service";
import {RfaStore} from "./rfa/rfa.store";
import {BasicStore} from "./basic.store";

@Injectable(
  {providedIn: 'root'}
)
export class DocumentStore extends BasicStore {

  private documents$ = new BehaviorSubject<DocumentHeader[]>([]);

  constructor(private dmxDocumentService: DmxDocumentService, private rfaStore: RfaStore) {
    super(dmxDocumentService);
    this.init();
  }


  public init() {
    this.rfaStore.getSelectedRfa().subscribe({
      next: data => {
        if (data) this.loadAllDocuments(data.kuerzel);
      },
      error: err => this.handleError(err),
      complete: () => console.log('INFO: loadAllDocuments completed')
    });
  }

  private loadAllDocuments(rfaKuerzel: string) {
    this.startLoad();
    this.dmxDocumentService.loadDocumentHeaders(rfaKuerzel).subscribe({
      next: data => {
        console.log(`INFO: fetched all documents. Total: ${data.length} `);
        this.documents$.next(data);
        this.stopLoad();
      },
      error: err => this.handleError(err),
    });
  }

  public updateDocumentHeader(rfaKuerzel: string, document: DocumentHeader){
    this.startLoad();
    this.dmxDocumentService.patchDocument(rfaKuerzel, document).subscribe({
      next: (response) => {
        console.log("PATCH call successful value returned in body", JSON.stringify(response.body));
        let docu:DocumentModel = response.body as DocumentModel;
        this.patchDocument(docu.header);
        this.stopLoad();
      }, error: err => {
        console.error("PATCH call in error", err);
        this.handleError("Dokument konnte nicht geändert werden.");
      }, complete: () => {
        console.log("The PATCH observable is now completed.");
      }
    })

  }

  public getDocuments() {
    return this.documents$.asObservable();
  }

  public deleteDocument(documentId: string | undefined) {

    if (documentId) {
      this.dmxDocumentService.deleteDocumentById(documentId).subscribe({
        next: (response) => {
          console.log("DELETE call successful value returned in body", JSON.stringify(response.body));
          const documents = this.documents$.getValue();
          const index = documents.findIndex(d => d.documentId === documentId);
          console.log("DELETE call successful index", JSON.stringify(index));
          if (index > -1) {
            documents.splice(index, 1);
            this.documents$.next(documents);
          }
        }, error: err => {
          console.error("DELETE call in error", err);
          this.startLoad();
          this.handleError("Dokument konnte nicht gelöscht werden.");
        }, complete: () => {
          console.log("The DELETE observable is now completed.");
        }
      })
    }
  }

  public addDocument(document: DocumentHeader) {
    this.documents$.next([...this.documents$.getValue(), document]);
  }

  public patchDocument(document: DocumentHeader) {
    const documents = this.documents$.getValue();
    const index = documents.findIndex(d => d.documentId === document.documentId);
    documents[index] = document;
    this.documents$.next(documents);
  }

  public setDocumentsUnpublished(listOfUnpublishedDocuments: UnpublishedDocuments[]) {
    const documents = this.documents$.getValue();
    listOfUnpublishedDocuments.forEach((unpublishedDocument) => {
      const index = documents.findIndex(d => d.documentId === unpublishedDocument.documentId);
      documents[index].unpublished = unpublishedDocument.unpublished;
    });
    this.documents$.next(documents);
  }

  addOrUpdateDocument(document: DocumentHeader) {
    const documents = this.documents$.getValue();
    const index = documents.findIndex(d => d.documentId === document.documentId);
    if (index === -1) {
      this.addDocument(document);
    } else {
      this.patchDocument(document);
    }
  }


  changeStatusToPublished(ids: string[], status?: any) {
    const documents = this.documents$.getValue();
    ids.forEach(id => {
      const index = documents.findIndex(d => d.documentId === id);
      if (status) documents[index].status = status;
      else
        // TODO: Dieser Block sollte dann weg
        documents[index].status = {
          code: 0,
          kuerzel: 'Veröffentlichen',
          description: 'Datensatz veröffentlicht',
          icon: 'publish'
        };
    });
    this.documents$.next(documents);
  }
}
